import React from "react";
import styled from "styled-components";
import GoodHabitLogo from "../../assets/images/good_habit_circle_logo.png";
import TodayHabitLogo from "../../assets/images/store_icon.png";
import EDULogo from "../../assets/images/edu_logo.png";
import useScrollFadeIn from "../../customHooks/useScrollFadeIn";

const LinkSection = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10vh auto;
  .image-inherit-div {
    width: 150px;
    height: 150px;
  }

  .image-container {
    width: 20%;
    margin: 4vh 0;
  }
  .company-summery {
    width: 60%;
    margin: 4vh 0;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.5rem;
    }
    .sub-title {
      font-size: 1.1rem;
      word-break: keep-all;
    }
    .sub-title p:first-child {
      color: blue;
    }
    .mb {
      margin-bottom: 3vh;
    }
    .mt {
      margin-top: 3vh;
    }
    > button {
      font-size: 0.8rem;
      background: none;
      padding: 1rem;
      border: 1px solid rgba(112, 112, 112, 1);
      border-radius: 20px;
      color: var(--page--gray-color);
    }
    > button:hover {
      border: 1px solid white;
      border-radius: 20px;
      background-color: var(--today-green);
      color: white;
    }
  }
  * {
    white-space: normal;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .company-summery {
      text-align: center;
    }
    .image-container {
      width: auto;
      margin-bottom: 1vh;
    }
  }
`;

export const CompanyProduct = () => {
  return (
    <>
      <LinkSection>
        <div className="image-container">
          <div className="image-inherit-div">
            <img src={GoodHabitLogo} alt="logo"></img>
          </div>
        </div>

        <div className="company-summery">
          <div className="title">수도권 누적회원 80,000명이 선택한</div>
          <div className="title mb">
            쉽고 재미있는 그룹 운동 서비스, 좋은습관 PT STUDIO
          </div>

          <p className="sub-title">
            수도권 50여개 지점을 보유하고 있으며, 매일 1,600명의 회원과 함께하고
          </p>
          <p className="sub-title">
            있습니다. 현대인의 바쁜 라이프스타일에 최적화된 서비스를 제공합니다.
          </p>
          <button
            className="mt"
            onClick={() => window.open("https://goodhabit-pt.imweb.me/contact")}
          >
            우리 동네에서 가장 가까운 좋은습관은?
          </button>
        </div>
      </LinkSection>
      <LinkSection {...useScrollFadeIn("down", 0.8)}>
        <div className="image-container">
          <div className="image-inherit-div iga">
            <img src={EDULogo} alt="logo"></img>
          </div>
        </div>

        <div className="company-summery">
          <div className="title">그룹 운동 전문가를 만드는</div>
          <div className="title mb">아카데미의 메카</div>

          <p className="sub-title">
            "세계적인 대세, 그룹운동 코칭서비스를 교육하는 아카데미입니다."
          </p>
          <p className="sub-title ">
            어려운 코칭만큼 CS와 실질적인 업무를 위한 커리큘럼을 준비했습니다!
          </p>
          <button
            className="mt"
            onClick={() => window.open("https://goodhabitpt.com/ghedu")}
          >
            좋은습관 EDU 보러가기
          </button>
        </div>
      </LinkSection>
      <LinkSection {...useScrollFadeIn("down", 0.8)}>
        <div className="image-container">
          <div className="image-inherit-div">
            <img src={TodayHabitLogo} alt="logo"></img>
          </div>
        </div>

        <div className="company-summery">
          <div className="title">오늘의 건강한 라이프스타일을 위한</div>
          <div className="title mb">예약서비스, 오늘의습관</div>

          <p className="sub-title">운영의 효율을 올려주고, 매출을 상승시키는</p>
          <p className="sub-title">오늘의 습관과 함께 해보세요!</p>
          <button
            className="mt"
            onClick={() =>
              window.open("https://admin.bookinghabit.com/register")
            }
          >
            오늘의습관 시작하기
          </button>
        </div>
      </LinkSection>
    </>
  );
};
