import React from 'react';
import { Container } from './PI';
import styled from "styled-components";

function ToS() {
  return (
    <Container>
      <h1 className='title'>오늘의습관 이용약관</h1>
      <br />
      <p>
        {`씨에치앤플랫폼은 피트니스 스튜디오 수업 예약, 출석체크 및 수강내역 조회 등을 제공하는 서비스인 오늘의습관을
           운영합니다. 본 이용약관은 오늘의습관이 제공하는 서비스를 사용하는 모든 사용자(이하 '고객'이라고 합니다)
          가 오늘의습관 앱(브랜디드 앱 서비스가 제공되는 경우 해당 브랜디드 앱을 의미하며, 이하 같습니다) 및 제반 서비스를
           사용하는 데에 적용됩니다. 본 이용 약관에 언급되는 '오늘의습관 서비스', '당사 서비스' 또는 '서비스' 는 모든
           특성과 기능, 출결 및 예약 내역, 웹사이트 및 사용자 인터페이스 뿐만이 아니라, 오늘의습관 서비스와 관련된 모든
           콘텐츠와 소프트웨어를 포함하여 오늘의습관이 제공하는 일체의 서비스를 의미합니다. 서비스를 이용하기 위해
           귀하는 본 약관에 동의하게 되므로 본 약관을 주의 깊게 읽어보시기 바라며, 귀하는 서비스 내에서 적용되는 모든
           정책을 준수해야 합니다. 본 약관내에는 영리목적 광고성 정보 수신동의에 관한 사항이 포함되어 있습니다.`}
      </p>
      <br />
      <br />
      <h3>제1조 약관의 목적</h3>
      <br />
      <p>
        {`이 약관은 오늘의습관이 온라인으로 제공하는 오늘의습관 앱 및 서비스 이용과 관련하여 오늘의습관과 고객과의
          권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.`}
      </p>
      <br />
      <br />
      <h3>제2조 약관의 명시, 효력 및 변경</h3>
      <br />
      <p>1. 본 약관의 내용은 오늘의습관이 제공하는 서비스 가입 과정에 게시합니다.</p>
      <br />
      <p>
        {`2. 고객은 본 약관의 규정에 따라 본 서비스를 이용해야 합니다. 고객은 본 약관에 대해 적법, 유효한 동의를 했을
          경우에 한하여 본 서비스를 이용할 수 있습니다.`}
      </p>
      <br />
      <p>3. 오늘의습관은 관련 법령에 위배되지 않는 범위에서 본 약관을 변경할 수 있습니다.</p>
      <br />

      <p>
        {`4. 고객은 오늘의습관이 전항에 따라 변경하는 약관에 동의하지 않을 권리가 있으며, 이 경우 고객은 오늘의습관에서
          제공하는 서비스 이용 중단 및 탈퇴 의사를 표시하고 서비스 이용 종료를 요청할 수 있습니다. 다만, 오늘의습관이
           고객에게 변경된 약관의 내용을 통보하면서 고객에게 "7일 이내 의사 표시를 하지 않을 경우 의사 표시가 표명
          된 것으로 본다는 뜻" 을 명확히 통지하였음에도 불구하고, 거부의 의사표시를 하지 아니한 경우 고객이 변경된 약관에
           동의하는것으로 봅니다.`}
      </p>
      <br />
      <br />
      <h3>제3조 용어의 정의</h3>
      <br />
      <p>
        {`1. 서비스: 개인용 컴퓨터 (PC), TV, 휴대형 단말기, 전기통신설비 등 포함 각종 유무선 장치와 같이 구현되는 단말기와
           상관없이 오늘의습관이 고객에게 제공하는 일체의 서비스를 의미합니다.`}
      </p>
      <br />
      <p>
        {`2. 고객: 오늘의습관이 제공하는 서비스를 이용하는 사용자(단, 브랜디드 앱 서비스의 고객은 파트너가 제공하는
          요가, 피트니스 등 파트너가 정하는 수업 서비스 의 회원으로서 오늘의습관이 제공하는 브랜디드 앱 서비스를 사용하는
           사용자)를 의미합니다.`}
      </p>
      <br />
      <p>
        {`3. 아이디: 고객의 식별 및 서비스 이용을 위하여 고객이 선정하고 오늘의습관이 부여한 문자 및 숫자의 조합을 의미합니다.`}
      </p>
      <br />
      <p>
        {`4. 비밀번호: 고객의 개인 정보 및 확인을 위해서 고객이 정한 문자 또는 숫자의 조합을 의미합니다.`}
      </p>
      <br />
      <p>
        {`5. 도메인: 고객의 서비스이용을 위하여 고객이 신청하여 오늘의습관이 부여한 고유한 인터넷 주소를 의미합니다.
          오늘의습관은 제공하는 서비스를 위해서 서비스에 따라 별도의 도메인 주소를 추가적으로 제공합니다.`}
      </p>
      <br />
      <p>
        {`6. 게시물: 고객이 서비스를 이용함에 있어 고객이 서비스에 게시한 문자, 문서, 그림, 음성, 링크, 파일 혹은 이들의
          조합으로 이루어진 정보 등 모든 정보나 자료를 의미합니다.`}
      </p>
      <br />
      <p>
        7. 유료 서비스: 오늘의습관이 고객에게 유료로 제공하는 각종 온라인 디지털콘텐츠 및 제반 서비스를 의미합니다.
      </p>
      <br />
      <br />
      <h3>제4조 이용계약의 체결</h3>
      <br />
      <p>
        {`1. 이용계약은 고객이 서비스에서 제공하는 회원 가입 페이지에서 서비스 이용약관에 동의한 후 이용 신청을 하고
          신청한 내용에 대해서 파트너 관리자가 승낙함으로써 체결됩니다.`}
      </p>
      <br />
      <p>
        {`2. 오늘의습관은 다음 각 호에 해당하는 신청에 대해서 승낙하지 않거나 사후에 이용계약을 해지할 수 있습니다.
          가입신청자가 이 약관에 의하여 이전에 고객자격을 상실한 적이 있는 경우 제3자의 전자우편 주소를 이용하여 신청한
           경우 허위의 정보를 기재하거나, 오늘의습관이 필수적으로 입력을 요청한 부분을 기재하지 않은 경우 부정한
           용도로 서비스를 사용하고자 하는 경우`}
      </p>
      <p>
        {`이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우 오늘의습관의
          정책에 적합하지 않는 고객으로 판단되는 경우나 서비스 제공이 곤란한 경우고객의 이용 목적이나 서비스 이용 방법이
           오늘의습관 의재산권이나 영업권을 침해하거나 침해할 우려가 있는 경우비정상적인 방법을 통하여 아이디 및
          도메인을 대량으로 생성하는 행위기타 관련 법령에 위반될 우려가 있거나 위반된 경우`}
      </p>
      <br />
      <p>
        {`3. 오늘의습관은 고객에 대해 오늘의습관 정책에 따라 등급별로 구분하여 서비스 메뉴, 기능, 제공 정보 등을 세분하여
           이용에 차등을 둘 수 있습니다.`}
      </p>
      <br />
      <p>4. 고객은 오늘의습관에 언제든지 회원 탈퇴를 요청하여 이용계약을 해지할 수 있습니다.</p>
      <br />
      <p>
        {`5. 고객은 회원 가입 시 기재한 개인 정보의 내용에 변경이 발생한 경우, 즉시 변경사항을 정정하여 기재하여야
          합니다. 변경의 지체로 인하여 발생한 고객의 손해에 대해 오늘의습관은 책임을 지지 않습니다.`}
      </p>
      <br />
      <p>
        {`6. 오늘의습관은 관련 법률 및 오늘의습관의 개인정보처리방침에서 정한 바에 따라 고객의 회원정보(개인정보를
          포함합니다) 및 기타 정보 항목을 추가, 삭제 등 변경하여 수집 및 이용하거나 파트너사 등 제3자에게 제공할 수 있습니다.`}
      </p>
      <br />
      <br />
      <h3>제5조 오늘의습관의 의무</h3>
      <br />
      <p>1. 오늘의습관은 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여 노력합니다.</p>
      <br />
      <p>
        {`2. 오늘의습관은 고객이 안전하게 서비스를 이용할 수 있도록 현재 인터넷 보안 기술의 발전 수준과 오늘의습관이
          제공하는 서비스의 성격에 적합한 보안 시스템을 갖추고 운영해야 합니다.`}
      </p>
      <br />
      <p>
        {`3. 오늘의습관은 정보통신망 이용 촉진 및 정보보호에 관한 법률, 통신비밀 보호법, 전기통신사업법 등 서비스의 운영,
           유지와 관련 있는 법규를 준수합니다.`}
      </p>
      <br />
      <br />
      <h3>제6조 개인정보보호 의무</h3>
      <br />
      <p>
        {`1. 오늘의습관은 정보통신망법 등 관계 법령이 정하는 바에 따라 고객의 개인 정보를 보호하기 위해 노력합니다. 개인
           정보의 보호 및 사용에 대해서는 관련 법 및 오늘의습관의 개인정보처리방침이 적용됩니다. 다만, 오늘의습관의
          공식 사이트 이외의 링크된 사이트에서는 오늘의습관의 개인정보처리방침이 적용되지 않습니다.`}
      </p>
      <br />
      <p>
        {`2. 오늘의습관 개인정보처리방침은 고객이 서비스 를 사용할 때 오늘의습관이 개인 정보를 어떻게 취급하고 보호하는지에
           대해 설명합니다. 서비스를 사용함으로써 고객은 오늘의습관이 개인정보처리방침에 따라 본인의 개인 정보를
           사용할 수 있음에 동의하게 됩니다.`}
      </p>
      <br />
      <p>
        {`3. 오늘의습관은 서비스를 중단하거나 고객이 개인 정보 제공 동의를 철회한 경우에는 신속하게 고객의 개인 정보를
           파기합니다. 단, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법률에서 정하는 바에 따라 일정 정보는
           1년동안 저장 보관된 후 삭제하거나 파기합니다.`}
      </p>
      <br />
      <p>
        {`4. 오늘의습관은 서비스 개선 및 고객 대상의 서비스 소개 등의 목적으로 고객의 동의하에 관계 법령에서 정하는 바에
           따라 추가적인 개인 정보를 수집할 수 있습니다.`}
      </p>
      <br />
      <p>
        {`5. 오늘의습관은 법률에 특별한 규정이 있는 경우를 제외하고는 고객의 별도 동의 없이 고객의 계정 정보를 포함한
          일체의 개인정보를 제 3자에게 공개하거나 제공하지 아니합니다.`}
      </p>
      <br />
      <p>
        {`6. 오늘의습관은 향후 제공하는 서비스에서 고객의 편의를 위해서 고객의 계정 정보를 사용할 수 있도록 링크 및 기타
           방법을 제공할 수 있습니다`}
      </p>
      <br />
      <br />
      <h3>제7조 고객의 의무</h3>
      <br />
      <p>
        1. 고객은 다음에 해당하는 행위를 해서는 안됩니다. <br/><br/>
        <CircleItem>
          <Circle/>
          <p>이용 신청 또는 회원정보 변경 시 허위 내용 등록</p>
        </CircleItem>
        <CircleItem>
          <Circle/>
          <p>타인의 정보 도용</p>
        </CircleItem>
        <CircleItem>
          <Circle/>
          <p>오늘의습관의 운영자, 임직원, 오늘의습관을 사칭하거나 관련 정보를 도용</p>
        </CircleItem>
        <CircleItem>
          <Circle/>
          <p>오늘의습관이 게시한 정보의 변경</p>
        </CircleItem>
        <CircleItem>
          <Circle/>
          <p>오늘의습관과 기타 제3자의 저작권, 영업 비밀, 특허권 등 지적 재산권에 대한 침해</p>
        </CircleItem>
        <CircleItem>
          <Circle/>
          <p>오늘의습관과 다른 고객 및 기타 제3자를 희롱하거나, 위협하거나 명예를 손상시키는 행위</p>
        </CircleItem>
        <CircleItem>
          <Circle/>
          <p>외설, 폭력적인 메시지, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위</p>
        </CircleItem>
        <CircleItem>
          <Circle/>
          <p>해킹을 통해서 다른 사용자의 정보를 취득하는 행위- 기타 현행 법령에 위반되는 불법적인 행위</p>
        </CircleItem>
      </p>
      <br />
      <br />
      <p>
        {`2. 오늘의습관은 고객이 전항에서 금지한 행위를 하는 경우, 위반 행위의 경중에 따라 서비스의 이용정지/계약의 해지
           등 서비스 이용 제한, 수사 기관에의 고발 조치 등 합당한 조치를 취할 수 있습니다.`}
      </p>
      <br />
      <p>
        {`3. 고객은 오늘의습관의 명시적 사전 동의가 없는 한 서비스의 이용권한 및 기타 이용계약상의 지위를 제 3자에게
          양도, 증여, 대여할 수 없으며 이를 담보로 제공할 수 없습니다.`}
      </p>
      <br />
      <p>
        {`4. 고객은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 오늘의습관이 통지하는 사항
          등을 준수하여야 하며, 기타 오늘의습관의 업무에 방해되는 행위를 하여서는 안 됩니다.`}
      </p>
      <br />
      <p>
        {`5. 고객은 오늘의습관의 사전 허락 없이 오늘의습관이 정한 이용 목적과 방법에 반하여 영업/광고 활동 등을 할 수
          없고, 고객의 종합 서비스 이용이 오늘의습관의 재산권, 영업권 또는 비즈니스 모델을 침해하여서는 안됩니다.`}
      </p>
      <br />
      <br />
      <h3>제8조 서비스의 제공 및 변경</h3>
      <br />
      <p>
        1. 오늘의습관은 고객에게 아래와 같은 서비스를 제공합니다. <br/>
        <CircleItem>
          <Circle/>
          <p>오늘의습관 앱 및 서비스</p>
        </CircleItem>
        <CircleItem>
          <Circle/>
          <p>오늘의습관이 추가 개발하거나 다른 제휴계약 등을 통해 고객에게 제공하는 일체의 서비스</p>
        </CircleItem>
      </p>
      <br />
      <p>
        {`2. 오늘의습관은 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의
          제공을 일시적으로 중단할 수 있습니다. 이 경우 오늘의습관은 고객에게 공지사항 게시판 및 메일 등의 방법으로 통지합니다.
           다만, 오늘의습관이 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.`}
      </p>
      <br />
      <p>
        {`3. 오늘의습관이 제공하는 서비스의 형태와 기능, 디자인 등 필요한 경우 수시로 변경되거나, 중단될 수 있습니다.
          오늘의습관은 이 경우 개별적인 변경에 대해서 고객에게 사전 통지하지 않습니다. 다만, 고객에게 불리한 것으로
          판단되는 경우 전자우편으로 통하여 이를 공지합니다.`}
      </p>
      <br />
      <p>
        4. 전항에 의해서 제공되는 서비스가 변경 및 중단될 경우 무료로 제공되는 서비스에 대해서는 고객에게 별도로 보상하지 않습니다.
      </p>
      <br />
      <br />
      <h3>제9조 광고의 게재</h3>
      <br />
      <p>
        {`1. 오늘의습관은 서비스 운영과 관련하여 회원 정보, 고객이 입력한 정보를 활용하여 파트너와 사전 서면 합의에 의한
          경우에 한하여, 광고를 게재할 수 있습니다.. 고객은 서비스 이용 시 노출되는 맞춤 광고 게재에 대해 동의합니다.`}
      </p>
      <br />
      <p>
        {`2. 오늘의습관은 서비스상에 게재되어 있거나 서비스를 통한 광고주의 판촉활동에 고객이 참여하거나 교신 또는
          거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.`}
      </p>
      <br />
      <p>
        {`3. 오늘의습관은 인구통계학적 특성에 따른 광고의 게재, 서비스의 유효성 확인, 경품지급, 사은행사 등 고객의 편의
           및 참여기회 제공, 접속빈도 파악, 고객의 서비스이용에 대한 통계 등의 목적으로 개인정보를 처리합니다. 이는
          제휴사 서비스(상품) 및 이벤트 제공 및 광고, SMS서비스 제공 등의 마케팅 활동, 시장조사 및 상품 개발ㆍ연구,
          우편물ㆍE-mail 등 배송, 전화상담 등에 이용될 수 있습니다.`}
      </p>
      <br />
      <br />
      <h3>제10조 서비스 이용의 제한</h3>
      <br />
      <p>
        {`1. 오늘의습관은 천재지변이나 국가비상사태, 해결이 곤란한 기술적 결함 또는 서비스 운영의 심각한 변화 등 불가항력적인
           경우가 발생 또는 발생이 예상될 때는 서비스의 전부 또는 일부를 예고 없이 제한하거나 중지할 수 있습니
          다.`}
      </p>
      <br />
      <p>
        {`2. 서비스를 이용하게 됨으로써 서비스 영역에서 발생하는 고객 사이의 문제에 대해 오늘의습관은 책임을 지지 않습니다.`}
      </p>
      <br />
      <p>
        {`3. 고객의 관리 소홀로 인하여 ID 및 비밀번호의 유출로 인해 고객에게 서비스 이용상의 손해가 발생하거나 제3자에
           의한 부정이용 등으로 고객의 의무조항을 위반한 경우 ID및 해당 도메인의 이용이 제한될 수 있습니다.`}
      </p>
      <br />
      <p>
        {`4. 고객이 본 약관 제9조의 위반 행위를 조사하는 과정에서 당해 고객 ID및 도메인이 특정 위반행위에 직접적으로
          관련되어 있는 경우 등 다른 고객의 권익 보호 및 서비스의 질서유지를 위해 불가피할 경우에는 해당 ID 및 도메인의
           이용을 일시적으로 정지할 수 있습니다. 이에 대해 고객은 서비스 홈페이지 또는 전자 우편 등을 통해 이의신청을
          할 수 있습니다.`}
      </p>
      <br />
      <br />
      <h3>제11조 기밀유지</h3>
      <br />
      <p>
        {`오늘의습관과 고객은 본 약관의 체결 여부 및 그 이행으로 취득한 상대방의 경영정보, 영업비밀, 고객정보 등 제반
          비밀사항에 대해서는 이용계약 종료 이후에도 그 사항을 상대방의 사전 서면 승인 없이는 제3자에게 제공, 공개,
          누설하거나 본 약관에서 정한 목적 외의 목적으로 사용해서는 아니되며 상대방이 요구하는 비밀사항을 철저히 준수해야
           합니다.`}
      </p>
      <br />
      <br />
      <h3>제12조 양도금지</h3>
      <br />
      <p>
        {`오늘의습관과 고객은 본 약관에 의한 모든 권리와 의무를 상대방의 서면 동의 없이 제3자에게 양도할 수 없습니다.
          단, 오늘의습관은 브랜디드 앱 서비스와 관련하여 특별한 사정이 존재하는 경우 고객에 대한 사전 서면 통지(또는
          공지) 절차를 거쳐 해당 파트너에 본 약관에 따른 권리 및 의무의 전부 또는 일부를 예외적으로 양도할 수 있습니다.`}
      </p>
      <br />
      <br />
      <h3>제13조 서비스 이용의 중지 및 해지</h3>
      <br />
      <p>
        {`1. 고객은 오늘의습관에 언제든지 회원 탈퇴를 요청할 수 있으며, 오늘의습관은 이와 같은 요청을 받았을 경우,
          오늘의습관이 별도로 고지한 방법에 따라 신속하게 처리합니다.`}
      </p>
      <br />
      <p>
        {`2. 고객이 서비스의 이용중지를 원하는 경우에는 오늘의습관이 제공하는 서비스 페이지 또는 전자우편 등의 방법으로
           오늘의습관에 중지신청을 할 수 있습니다. 오늘의습관은 이와 같은 요청을 받았을 경우, 오늘의습관이 별도로
          고지한 방법에 따라 신속하게 처리합니다.`}
      </p>
      <br />
      <p>
        {`3. 고객은 고객이 본 약관 제9조의 이용자의 의무를 위반한 경우 및 서비스의 정상적인 운영을 방해한 경우에는 사전통보
           후 고객 자격을 제한, 이용계약을 해지하거나 또는 기간을 정하여 서비스의 이용을 중지할 수 있습니다.`}
      </p>
      <br />
      <p>
        {`4. 고객은 전항에도 불구하고, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을
           위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우
          에는 즉시 영구이용정지를 할 수 있습니다.`}
      </p>
      <br />
      <p>
        {`5. 오늘의습관은 고객이 계속해서 1년동안 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을
          제한하거나 고객의 개인정보를 보호하기 위하여 개인정보를 파기 처리합니다.`}
      </p>
      <br />
      <p>
        {`6. 고객은 본 조에 따른 이용제한 등에 대해 오늘의습관이 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가
          정당하다고 오늘의습관이 인정하는 경우 오늘의습관은 즉시 서비스의 이용을 재개합니다.`}
      </p>
      <br />
      <br />
      <h3>제14조 책임제한</h3>
      <br />
      <p>
        {`1. 오늘의습관은 고객의 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는 등 고객의 귀책사유로 인한 서비스
          이용의 장애에 대하여는 책임을 지지 않습니다.`}
      </p>
      <br />
      <p>
        {`2. 오늘의습관은 서비스를 통하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 보증하지 않습니다.`}
      </p>
      <br />
      <p>
        {`3. 오늘의습관은 고객 간 또는 고객과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.`}
      </p>
      <br />
      <p>
        4. 오늘의습관은 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
      </p>
      <br />
      <p>
        {`5. 오늘의습관은 천재지변, 전쟁, 기간통신사업자의 서비스 중지, 제3자가 제공하는 오픈아이디의 인증 장애, 해결이
           곤란한 기술적 결함 기타 불가항력으로 인하여 서비스를 제공할 수 없는 경우 책임이 면제됩니다.`}
      </p>
      <br />
      <p>
        {`6. 오늘의습관은 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나
             장애가 발생한 경우에 대해서는 책임이 면제됩니다.`}
      </p>
      <br />
      <p>
        {`7. 고객은 자신의 결정에 의하여 오늘의습관의 서비스를 사용하여 특정 프로그램이나 정보 등을 다운받거나 접근함으로써
           입게되는 컴퓨터 시스템상의 손해나 데이터, 정보의 상실에 대한 책임을 집니다.`}
      </p>
      <br />
      <p>
        {`8. 오늘의습관은 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한
          경우에는 책임이 면제됩니다.`}
      </p>
      <br />
      <p>
        {`9. 오늘의습관은 고객의 컴퓨터 오류, 신상정보 및 전자우편 주소의 부정확한 기재, 비밀번호 관리의 소홀 등 고객의 
          귀책사유로 인해 손해가 발생한 경우 오늘의습관은 책임을 지지 않습니다.`}
      </p>
      <br />
      <p>
        {`10. 오늘의습관은 고객의 컴퓨터 환경이나 오늘의습관의 관리 범위에 있지 아니한 보안 문제로 인하여 발생하는 제반 
          문제 또는 현재의 보안기술 수준으로 방어가 곤란한 네트워크 해킹 등 오늘의습관의 귀책사유 없이 발생하는 문제에
           대해서 책임을 지지 않습니다.`}
      </p>
      <br />
      <p>
        {`11. 오늘의습관은 서비스 내 제공된 내용에 대하여 중요 정보의 정확성, 내용, 완전성, 적법성, 신뢰성 등에 대하여 
          보증하거나 책임을 지지 않으며, 사이트의 삭제, 저장실패, 잘못된 인도, 정보에 대한 제공에 대한 궁극적인 책임을 
          지지 않습니다. 또한, 오늘의습관은 고객이 서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도,
           정확성, 완결성, 품질 등 내용에 대해서는 책임을 지지 않습니다.`}
      </p>
      <br />
      <p>
        {`12. 오늘의습관은 고객 상호간 또는 고객과 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가
          없으며 이로 인한 손해를 배상할 책임도 없습니다.`}
      </p>
      <br />
      <p>
        {`13. 오늘의습관은 고객이 서비스를 이용하여 기대하는 효용을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에
          대한 취사 선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.`}
      </p>
      <br />
      <br />
      <h3>제15조 약관의 해석과 예외 준칙</h3>
      <br />
      <p>
        {`1. 오늘의습관은 제공하는 개별 서비스에 대해서 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 이 약관과
          상충할 경우 개별 서비스의 이용약관을 우선하여 적용합니다.`}
      </p>
      <br />
      <p>
        2. 본 약관에 명시 되지 않은 사항이 관계법령에 규정되어 있을 경우에는 그 규정에 따릅니다.
      </p>
      <br />
      <br />
      <h3>제15조의 1 기타</h3>
      <br />
      <p>
        {`본 약관의 일부 조항이 무효, 불법 또는 강제성을 상실하는 경우라도, 본 약관의 남아 있는 조항의 정당성, 적법성
          및 강제성은 어떤 방식으로도 영향을 받거나 약화되지 않습니다.`}
      </p>
      <br />
      <p>
        {`본 약관은 고객과  오늘의습관사이에 앱 서비스 이용에 대한 완전한 계약을 구성하며, 그러한 내용과 관련된 모든
          기존 또는 동시에 발생한 합의에 우선합니다.본 약관 하의 어떠한 권리를 행사하지 않거나 행사함에 있어 지체하는
          것이 해당 권리의 포기로써 작용하지는 않습니다.`}
      </p>
      <br />
      <br />
      <h3>제16조 준거법 및 재판관할</h3>
      <br />
      <p>1. 오늘의습관과 고객 간 제기된 소송에는 대한민국법을 준거법으로 합니다.</p>
      <br />
      <p>
        2. 오늘의습관과 고객 간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.
      </p>
      <br />
      <br />
    </Container>
  );
}

export const Circle = styled.span`
  position: absolute;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: black;

  @media screen and (max-width: 800px) {
    top: 6px;
    width: 5px;
    height: 5px;
    margin-right: 15px;
  }
`;

export const CircleItem = styled.div`
  position: relative;
  display: flex;
  
  p {
    margin-left: 35px;

    @media screen and (max-width: 800px) {
      margin-left: 20px;
    }
  }
`;

export default ToS;
