import React from "react";
import styled, { keyframes } from "styled-components";
import FreeOpenButton from "../components/presentation/FreeOpenButton";
import phonePic1 from "../assets/images/phone_msg1.png";
import phonePic2 from "../assets/images/phone_msg2.png";
import webPic1 from "../assets/images/web_img1.png";
import webPic2 from "../assets/images/web_img2.png";
import useScrollFadeIn from "../customHooks/useScrollFadeIn";

const Container = styled.div`
  .home-p-1 {
    margin-top: 25vh;
    margin-bottom: 1vh;
  }

  .block {
    width: 100%;
    height: 100vh;
    opacity: 0.3;
    background-color: #ffffff;
    position: absolute;
    z-index: 10;
  }

  section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("/assets/background.jpeg");
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    > * {
      z-index: 100;
    }

    h1 {
      font-size: 3.5rem;
      color: #ffffff;
      font-weight: 500;
    }

    p {
      font-size: 1.5rem;
      color: #ffffff;
      font-weight: 500;
    }
  }

  img {
    object-fit: cover;
  }

  .web-in-summery {
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-top: 150px;

    .small-text {
      margin-top: 15px;
      font-size: 0.6rem;
      letter-spacing: 0.05em;
    }
  }

  .web-container {
    width: 100%;
    margin-bottom: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .pic-div {
      width: 45vw;
      margin-left: 2rem;
      background: #ffffff;
      margin-top: 20vh;

      img {
        object-fit: cover;
      }
    }

    @media screen and (max-width: 500px) {
      margin-bottom: 0;
      margin-top: 0;

      .web-in-summery {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    img {
      border-radius: 50px;
    }

    .web-in-summery {
      margin-bottom: 30rem;
    }

    .web-container {
      .pic-div {
        margin: 0;
        position: relative;
        margin-top: 30rem;
        width: 90vw;
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            margin-top: 15rem;
          }
        }
      }

      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (max-width: 500px) {
    .web-container .pic-div {
      margin-top: 40rem;
      margin-bottom: 80px;
      max-width: 330px;
    }
  }

  .webImage1 {
    position: absolute;
    bottom: -140px;
    left: 150px;

    @media screen and (max-width: 500px) {
      bottom: 100px;
      left: -20px;
      width: 100%;
      max-width: 400px;
      object-fit: contain;
    }
  }

  .webImage2 {
    position: absolute;
    top: -190px;
    left: 12px;

    @media screen and (max-width: 500px) {
      top: -280px;
      left: 35px;
      width: 100%;
      max-width: 400px;
      object-fit: contain;
    }
  }
`;
const Subscribe = styled.div`
  .phone-image {
    position: relative;
    width: 300px;
    height: 90vh;

    .base-image,
    .overlay-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 100px;
      width: 100%;
      height: 100%;
    }

    .overlay-image {
      top: 50%;
      left: 50%;
      transform: translate(-25%, -40%);
      z-index: 2;
    }
  }

  @media screen and (max-width: 500px) {
    .phone-image {
      width: 100%;
      height: 200px;
    }

    .base-image,
    .overlay-image {
      width: 100%;
      max-width: 200px;
      object-fit: contain;
    }
  }

  .pic-div {
    margin-top: 3vh;
    border-radius: 20px;
  }

  margin-top: 15vh;
  > div {
    margin-bottom: 40vh;

    @media screen and (max-width: 500px) {
      margin-bottom: 20vh;
    }
  }
  > h2,
  > p {
    margin-left: 1vw;
  }
  > h2 {
    margin-bottom: 3vh;
  }
  @media screen and (max-width: 500px) {
    > h2,
    > p {
      margin-left: 5vw;
    }
  }
`;
const bounceAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 0, 25);
  }
`;

const DownIcon = styled.div`
  width: 25px;
  height: 25px;
  perspective: 100px;
  margin-top: 15vh;
  -webkit-animation: ${bounceAnimation} 1.5s infinite;
  animation: ${bounceAnimation} 1.5s infinite;
  svg {
    transform: matrix(1, 0, 0, 1, 0, 50);
    -webkit-transform: matrix(1, 0, 0, 1, 0, 50);
    width: 100%;
    height: 100%;
    transform: translate3d(0px, 0px, 0px);
    fill: #ffffff;
    opacity: 0.6;
  }
  @media screen and (max-width: 500px) {
    margin-top: 30vh;
  }
`;

function Home() {
  return (
    <Container>
      <section className="section-1">
        <p className="home-p-1 animate__animated animate__fadeInDown">
          쉽고 똑똑한 센터관리서비스
        </p>
        <h1 className="animate__animated animate__fadeInDown">오늘의 습관</h1>
        <FreeOpenButton />
        <p>사업에 필요한 실질적인 데이터를</p>
        <p>제공해드려요</p>
        <DownIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M169.4 278.6C175.6 284.9 183.8 288 192 288s16.38-3.125 22.62-9.375l160-160c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0L192 210.8L54.63 73.38c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L169.4 278.6zM329.4 265.4L192 402.8L54.63 265.4c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25l160 160C175.6 476.9 183.8 480 192 480s16.38-3.125 22.62-9.375l160-160c12.5-12.5 12.5-32.75 0-45.25S341.9 252.9 329.4 265.4z" />
          </svg>
        </DownIcon>
        <div className="block"></div>
      </section>

      <Subscribe>
        <h2 style={{ margin: "1rem 0 " }} {...useScrollFadeIn("right", 0.3)}>
          쉽고, 똑똑하게
        </h2>
        <p {...useScrollFadeIn("right", 0.3)}>예약현황, 매출 뿐만 아니라</p>
        <p {...useScrollFadeIn("right", 0.3)}>
          실제 운영에 필요한 데이터를 리포팅 해드려요
        </p>
        <div {...useScrollFadeIn("up", 0.3)} className="phone-image">
          <img
            className="base-image"
            src={phonePic1}
            style={{ width: "500px", height: "100vh" }}
            alt="카카오톡 이미지 1"
          />
          <img
            className="overlay-image"
            src={phonePic2}
            style={{ width: "500px", height: "100vh" }}
            alt="카카오톡 이미지 2"
          />
        </div>
      </Subscribe>

      <div
        style={{ display: "flex", "align-items": "center" }}
        className="web-container"
      >
        <div className="web-in-summery">
          <h2>한눈에 볼 수 있는</h2>
          <h2 style={{ "margin-bottom": "3vh" }}>센터 현황</h2>
          <p>이달의 현황과 연간 추이까지</p>
          <p>한눈에 확인할 수 있어요</p>
          <FreeOpenButton />
        </div>
        <div className="pic-div" {...useScrollFadeIn("left", 0.3)}>
          <img className="webImage1" src={webPic1} alt="crm 이미지1" />
          <img className="webImage2" src={webPic2} alt="crm 이미지2" />
        </div>
      </div>
    </Container>
  );
}

export default Home;
