import React from "react";
import {Container} from "./PI";

function LocationServiceTermsPage() {
    return (
      <Container>
          <h1 className='title'>위치기반서비스 이용약관</h1>
          <br />
          <br />
          <br />
          <h4>제1조(개인정보의 처리 목적)</h4>
          <br />
          <p>
              {`본 약관은 유한책임회사 씨에이치앤플랫폼(이하 “회사”라고 합니다.)이 제공하는 위치기반서비스(이하 “서비
              스”라고 합니다.)를 이용함에 있어 고객(오늘의습관 위치기반서비스 약관에 동의한 자를 말합니다. 이하 “고객”이
              라고 합니다.) 및 개인위치정보주체의 권리, 의무 및 책임사항에 따른 이용조건 및 절차 등 기본적인 사항을 규정함
              을 목적으로 합니다.`}
          </p>
        <br />
        <br />
        <h4>제 2조 (이용약관의 효력 및 변경)</h4>
        <br />
        <p>
          {`① 본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라
            서비스의 이용자로 등록함으로써 효력이 발생합니다.
            
            ② 고객이 온라인에서 본 약관의 “동의” 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하
            였으며, 그 적용에 동의한 것으로 봅니다.
            
            ③ 회사는 서비스에 새로운 기능 적용, 정부에 의한 시정명령의 이행 및 기타 회사의 업무상 약관을 변경해야할 중
            요한 사유가 있다고 판단될 경우 본 약관을 개정할 수 있습니다.
            
            ④ 회사가 약관을 개정할 경우에는 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 적용일자 1주일 전까
            지 서비스 홈페이지에 게시하거나 고객에게 전자적 형태(전자우편, SMS 등)로 공지하며, 고객이 그 기간안에 이의
            제기가 없거나, 본 서비스를 이용할 경우 이를 승인한 것으로 봅니다.`}
        </p>
        <br />
        <br />
        <h4>제 3조 (관계법령의 적용)</h4>
        <br />
        <p>
          {`본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는
            상관례에 따릅니다.`}
        </p>
        <br />
        <br />
        <h4>제 4조 (서비스의 내용)</h4>
        <br />
        <p>
          {`회사가 위치정보사업자로부터 제공받은 위치정보수집대상의 위치정보 및 상태정보를 이용하여 제공하는 서비스는
            아래와 같습니다.
            - 위치정보수집대상의 실시간 위치 확인`}
        </p>
        <br />
        <br />
        <h4>제 5조 (서비스 이용요금)</h4>
        <br />
        <p>
          {`고객은 본 서비스를 무료로 이용할 수 있습니다. 다만 위치정보를 확인하기 위하여 이동통신망에 접속할 때 발생하
          는 비용인 통신요금이 발생할 수 있습니다. 통신 요금은 고객의 데이터 사용량, 이동통신사 등에 따라 변동될 수 있
          습니다.`}
        </p>
        <br />
        <br />
        <h4>제 6조 (정보의 제공)</h4>
        <br />
        <p>
          {`회사는 서비스를 운영함에 있어 각종 정보를 서비스 홈페이지 및 애플리케이션의 게시판 게재 또는 전자우편 등의
            방법으로 고객에게 제공할 수 있습니다.`}
        </p>
        <br />
        <br />
        <h4>제 7조 (서비스의 이용)</h4>
        <br />
        <p>
          {`① 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상이나 기술상의 이유로 서비스가
            일시 중지될 수 있고, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경
            우 회사는 사전 또는 사후에 이를 공지합니다.
            
            ② 회사는 서비스를 일정 범위로 분할하여 각 범위 별 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을
            공지합니다.`}
        </p>
        <br />
        <br />
        <h4>제 8조 (서비스이용의 제한 및 중지)</h4>
        <br />
        <p>
          {`① 회사는 아래 각 호의 1 에 해당하는 사유가 발생한 경우에는 고객의 서비스 이용을 제한하거나 중지시킬 수 있습
          니다.`}
        <div className='tab'>1. 고객이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우</div>
        <div className='tab'>2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</div>
        <div className='tab'>3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</div>
        <div className='tab'>4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</div>
        <div className='tab'>5. 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</div>
        <br />
        {`② 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 고객에게
        알리도록 노력해야 합니다.`}
        <br />
        <br />
        {`③ 제1항에 의한 장애로 인하여 서비스가 제한 또는 정지된 경우에는 회사는 일체의 책임을 지지 않습니다.`}
        </p>
        <br />
        <br />
        <h4>제 9조 (이용제한 및 해제 절차)</h4>
        <br />
        <p>
          {`① 회사는 제8조의 규정에 의하여 이용제한을 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 이용정지 7일
            전까지 전자우편, 애플리케이션 게시 등의 방법에 의하여 해당 고객 또는 대리인에게 통지합니다. 다만 긴급하게
            이용을 정지할 필요가 있다고 인정하는 경우에는 그러지 아니합니다.
            
            ② 제1항의 규정에 의하여 이용정지의 통지를 받은 고객 또는 대리인은 그 이용정지의 통지에 대하여 이의가 있을
            때에는 이의신청을 할 수 있습니다.
            
            ③ 회사는 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용정지를 일시 연기할 수 있으며,
            그 결과 고객 또는 그 대리인에게 통지합니다.
            
            ④ 회사는 이용정지 기간 중에 이용정지 사유가 해소된 것이 확인된 경우에는 이용정지조치를 즉시 해제합니다.`}
        </p>
        <br />
        <br />
        <h4>제 10조 (개인위치정보의 이용 또는 제공)</h4>
        <br />
        <p>
          {`① 회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 개인위치정보
          주체의 동의를 얻어야 합니다.
          
          ② 고객 및 법정대리인의 권리와 그 행사방법은 제소 당시의 고객의 주소에 의하며, 주소가 없는 경우에는 거소를
          관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 고객의 주소 또는 거소가 분명하지 않거나 외국 거주자
          의 경우에는 민사소송법상의 관할법원에 제기합니다.
          
          ③ 회사는 민원처리 등을 위한 위치정보 이용, 제공 사실 확인자료를 자동 기록, 보존하며, 해당 자료는 최대 1년간
            보관·이용할 수 있습니다. 고객이 개인위치정보의 이용 또는 제공에 대한 동의를 전부 또는 일부 철회한 경우에는
            법률에 따라 보전해야 하는 경우를 제외하고 지체없이 위치정보 이용 또는 제공과 관련한 데이터(동의의 일부 철회
            의 경우에는 철회와 관련된 부분)를 파기합니다.
            
          ④ 회사는 개인위치정보를 고객이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신단말
          장치로 매회 고객에게 제공받는 자, 제공 일시 및 제공목적을 즉시 통보합니다. 단, 아래 각 호의에 해당하는 경우에
          는 고객이 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보합니다.`}
            <div className='tab'>1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우</div>
            <div className='tab'>2. 고객이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우</div>
        </p>
        <br />
        <br />
        <h4>제 11 조 (개인위치정보주체의 권리)</h4>
        <br />
        <p>
          {`① 고객은 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에
            대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사
            실 확인자료를 파기합니다.
            
            ② 고객은 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있습니다.
            이 경우 회사는 요구를 거절하지 아니하며, 이를 위한 기술적 조치를 취합니다.
            
            ③ 고객은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경
            우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 고객의 요구를 거절하지 아니합니다.`}
            <div className='tab'>1. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료</div>
            <div className='tab'>2. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용</div>
            <br/>
            {`④ 회사는 고객이 위치정보 이용제공에 대한 동의의 전부 또는 일부를 철회한 경우에는 지체없이 개인위치정보 및
            위치정보 이용, 제공사실 확인자료(동의의 일부를 철회한 경우에는 철회하는 부분의 확인 자료)를 파기합니다.
            
            ⑤ 고객은 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.`}
        </p>
        <br />
        <br />
        <h4>제 12 조 (법정대리인의 권리)</h4>
        <br />
        <p>
          {`① 회사는 14세 미만의 고객에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자
            제공에 대한 동의를 당해 고객과 당해 고객의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 제
            11조에 의한 고객의 권리를 모두 가집니다.
            
            ② 회사는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용·제공사실 확인자료를 이용약관에 명시 또는 고지
            한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세미만의 아동과 그 법정대리인의 동의를 받
            아야 합니다. 단, 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하
            는 경우는 제외합니다.
            
            ③ 법정대리인은 14세 미만 아동의 위치정보 이용제공에 동의하는 경우 동의유보권(위치정보법 제19조제4항), 동
            의철회권 및 일시중지권, 열람/고지요구권(위치정보법 제 24조)을 행사할 수 있습니다.`}
        </p>
        <br />
        <br />
        <h4>제 13조 (8세 이하의 아동 등의 보호의무자의 권리)</h4>
        <br />
        <p>
          {`① 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라 한다)의 보호의무자가 8세 이하의 아동 등의
            생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로
            봅니다.`}
            <div className='tab'>1. 8세 이하의 아동</div>
            <div className='tab'>2. 피성년후견인</div>
            <div className='tab'>{`3. 장애인복지법 제2조 제2항 제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법
            제2조 제2호의 규정에 의한 중증장애인에 해당하는자(장애인복지법 제32조에 따라 장애인 등록을 한 사람만 해당
            한다.)`}</div>
            <br/>
            {`② 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자하는 보
            호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
            
            ③ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의
            전부를 행사할 수 있습니다.`}
        </p>
        <br />
        <br />
        <h4>제 14조 (위치정보관리책임자의 지정)</h4>
        <br />
        <p>
          {`① 회사는 위치정보를 적절히 관리·보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임
            을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.
            
            ② 위치정보관리책임자 지정의 구체적인 사항은 본 약관의 부칙에 따릅니다.`}
        </p>
        <br />
        <br />
        <h4>제 15조 (손해배상의 범위)</h4>
        <br />
        <p>
          {`① 회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 고객에게 손해가
            발생한 경우 고객은 회사에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이 없음을 입증하지
            못하는 경우 책임을 면할 수 없습니다.
            
            ② 고객이 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 고객에 대하여 손해배상을 청구할 수 있
            습니다. 이 경우 고객은 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
            
            ③ 회사는 고객이 망사업자의 통신환경에 따라 발생할 수 있는 오차 있는 위치정보를 이용함으로써 고객 및 제 3자
            가 입은 손해에 대해서는 배상하지 아니합니다.
            
            ④ 고객은 고객의 의무 등이 약관의 제규정을 위반함으로써 회사가 입은 일체의 손해에 대하여 이용계약이 해지된
            이후에도 배상할 책임이 있습니다.`}
        </p>
        <br />
        <br />
        <h4>제 16조 (면책)</h4>
        <br />
        <p>
          {`① 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 고객에게 발생한 손해에 대해서는 책임
            을 부담하지 않습니다.`}
            <div className='tab'>1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</div>
            <div className='tab'>2. 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우</div>
            <div className='tab'>3. 고객의 귀책사유로 서비스 이용에 장애가 있는 경우</div>
            <div className='tab'>4. 제1호 내지 제3호를 제외한 기타 회사의 고의·과실이 없는 사유로 인한 경우</div>
            <br/>
            {`② 회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로
            인해 발생한 고객의 손해에 대하여는 책임을 부담하지 아니합니다.`}
        </p>
        <br />
        <br />
        <h4>제 17조 (규정의 준용)</h4>
        <br />
        <p>
          {`① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.
            
            ② 본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다.`}
        </p>
        <br />
        <br />
        <h4>제 18조 (분쟁의 조정 및 기타)</h4>
        <br />
        <p>
          {`① 회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위
            치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.
            
            ② 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보
            보호법 제43조의 규정에 의한 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.`}
        </p>
        <br/>
        <br/>
        <p>본 약관 시행일자 : 2023년 10월 25일</p>
      </Container>
    )
}

export default LocationServiceTermsPage;