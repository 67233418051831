import React from "react";
import styled from "styled-components";

const FreeOpenBtn = styled.button`
  font-weight: bold;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  background: var(--today-green);
  color: #ffffff;
  font-size: 16px;
  margin-top: 10vh;
  margin-bottom: 5vh;

  @media screen and (max-width: 500px) {
    margin-top: 5vh;
    margin-bottom: 6vh;
  }
`;

function FreeOpenButton() {
  return (
    <FreeOpenBtn
      onClick={() => window.open("https://admin.bookinghabit.com/register")}
    >
      시작하기
    </FreeOpenBtn>
  );
}

export default FreeOpenButton;
