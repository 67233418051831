import React from "react";
import styled from "styled-components";

export const FeeTable = () => {
  return (
    <FeeTableContainer>
      <Column>
        <div className="mr">
          <div
            className="fee-select-box"
            style={{ border: "1px solid white", borderColor: "transparent" }}
          ></div>
          <BodyBox borderRadius="10px">
            <h4>관리자 기능</h4>
            <div>실시간 예약</div>
            <div>마일리지</div>
            <div>전자 계약서</div>
            <div>경영 리포트</div>
            <div>급여 정산 (예정)</div>
            <div>피드백 시스템 (예정)</div>
          </BodyBox>
          <BodyBox borderRadius="10px">
            <h4>회원 기능</h4>
            <div>실시간 예약</div>
            <div>예약 푸시 알림</div>
            <div>홀딩권 사용</div>
            <div>회원궐 결제 (예정)</div>
          </BodyBox>
        </div>
        <div className="mr">
          <div
            style={{
              borderRight: "none",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            className="fee-select-box"
          >
            <div>PRO 월 90,000원</div>
          </div>
          <BodyBox borderRadius="10px 0 0 10px">
            <h4>&nbsp;</h4>
            <div>○</div>
            <div>○</div>
            <div>○</div>
            <div>○</div>
            <div>-</div>
            <div>-</div>
          </BodyBox>
          <BodyBox borderRadius="10px 0 0 10px">
            <h4>&nbsp;</h4>
            <div>○</div>
            <div>○</div>
            <div>○</div>
            <div>-</div>
          </BodyBox>
        </div>
        <div className="mr">
          <div
            style={{
              borderLeft: "none",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              textAlign: "center",
            }}
            className="fee-select-box"
          >
            <div>PREMIUM 월 120,000원</div>
          </div>
          <BodyBox borderRadius="0 10px 10px 0">
            <h4>&nbsp;</h4>
            <div>○</div>
            <div>○</div>
            <div>○</div>
            <div>○</div>
            <div>○</div>
            <div>○</div>
          </BodyBox>
          <BodyBox borderRadius="0 10px 10px 0">
            <h4>&nbsp;</h4>
            <div>○</div>
            <div>○</div>
            <div>○</div>
            <div>○</div>
          </BodyBox>
        </div>
      </Column>
      <FloatingButton
        onClick={() =>
          window.open(
            "https://docs.google.com/forms/d/1VLF2ue-TPYyzRiZHJqHJcoWllBRs96eEm37Z_1Ay7sg/edit"
          )
        }
      >
        플랜
        <br />
        신청하기
      </FloatingButton>
    </FeeTableContainer>
  );
};

const FeeTableContainer = styled.div`
  width: 100%;
  margin-top: 10vh;
  padding: 0 15%;

  .mr:first-child {
    margin-right: 0.8rem;
  }
  .ml {
    margin-left: 0.8rem;
  }
  .fee-select-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    height: 2rem;
    background-color: #ffffff;
    border: 1px solid rgba(112, 112, 112, 1);
    border-radius: 10px;

    > div {
      display: flex;
    }
    div:first-child {
      align-items: center;
    }
  }
  .business-report {
    background-color: #e7f8f3;
    margin-bottom: 1.6rem !important;
    + div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .accumulation {
    font-size: 10px;
    margin-top: -3px;
    height: 3px;
  }
  @media screen and (max-width: 1100px) {
    width: 90%;
    padding: 0 5%;
    .select-items {
      width: 150%;
      left: 25%;
    }
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-top: 4vh;
    .accumulation {
      font-size: 0.75rem;
    }
  }
`;

const Column = styled.div`
  display: flex;

  > div:first-child {
    flex: 1;
  }
  > div:not(div:first-child) {
    flex: 1;
    text-align: center !important;
  }

  > div > * {
    margin-bottom: 0.8rem;
    padding: 1rem 1.5rem;
    background-color: rgba(248, 248, 248, 1);
  }
  > div > *:first-child {
    background-color: #ffffff;
  }
`;

const BodyBox = styled.div`
  padding: 0.5rem 0;
  background-color: rgba(248, 248, 248, 1);
  border-radius: ${(props) => props.borderRadius || "0"};

  & > *:not(h4) {
    padding: 0.5rem 0;
  }

  & > h4 {
    padding: 0.7rem 0;
  }

  &:first-child {
    background-color: #ffffff;
  }
`;

const FloatingButton = styled.button`
  position: fixed;
  width: 100px;
  height: 100px;
  bottom: 50px;
  right: 50px;
  border-radius: 50px;
  font-size: 14px;
  background-color: var(--today-green);
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  :focus {
    border: none;
    outline: none;
  }

  @media screen and (max-width: 1100px) {
    right: 35px;
  }
`;
