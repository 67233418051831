import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { FeeTable } from "./FeeTable";

const Container = styled.div`
  .summery {
    margin-top: 30vh;
    margin-bottom: 1vh;
  }
  .precautions {
    margin: 5vh 0;
  }

  span {
    font-size: 14px;
  }
  @media screen and (max-width: 800px) {
    .summery {
      margin-top: 20vh;
    }
    span {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 500px) {
    span {
      font-size: 8px;
    }
  }
`;

function FeeInformation() {
  return (
    <Container>
      <div className="summery animate__animated animate__fadeInDown">
        <h1>예약습관, 오늘의습관</h1>
      </div>
      <FeeTable />
      <p className="precautions">*이용 금액은 모두 VAT 별도 이용료입니다.</p>
    </Container>
  );
}
export default FeeInformation;
